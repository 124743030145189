/**
 * 项目管理-项目信息-编辑页
 * luxinwen
 * 2023-03-17
 */
<style scoped lang="less">
  @import "./edit.less";
</style>

<template>
  <div>
    <Drawer class="drawer" :title="title" v-model="myValue" width="900" :before-close="beforeClose">
      <div class="step">
        <Steps :current="currentStep">
          <Step title="项目属性"></Step>
          <Step title="项目信息"></Step>
          <Step title="项目详情"></Step>
        </Steps>
      </div>
      <Form ref="editForm" :model="formData" :rules="formRule" :label-width="100">
        <div v-show="currentStep === 0">
          <div class="flex">
            <div class="flex-item">
              <div class="flex-item-thead">请选择项目省份</div>
              <div class="flex-item-tbody">
                <p v-for="(item, index) in provinceList" :key="'prov' + index" :class="{'active': item.value === formData.provinceId}" @click="changeProvince(item)">{{ item.label }}</p>
              </div>
            </div>
            <div class="flex-item">
              <div class="flex-item-thead">请选择项目城市</div>
              <div class="flex-item-tbody">
                <p v-for="(item, index) in cityList" :key="'city' + index" :class="{'active': item.value === formData.cityId}" @click="changeCity(item)">{{ item.label }}</p>
              </div>
            </div>
            <div class="flex-item">
              <div class="flex-item-thead">请选择项目区域</div>
              <div class="flex-item-tbody">
                <p v-for="(item, index) in townList" :key="'town' + index" :class="{'active': item.value === formData.townId}" @click="changeTown(item)">{{ item.label }}</p>
              </div>
            </div>
            <div class="flex-item">
              <div class="flex-item-thead">请选择项目类型</div>
              <div class="flex-item-tbody">
                <p v-for="(item, index) in categoryList" :key="'cate' + index" :class="{'active': item.code === formData.category}" @click="changeCategory(item)">{{ item.desc }}</p>
              </div>
            </div>
            <div class="flex-item">
              <div class="flex-item-thead">请选择项目分类</div>
              <div class="flex-item-tbody">
                <p v-for="(item, index) in saleTypeList" :key="'type' + index" :class="{'active': formData.saleType.indexOf(item.code) !== -1}" @click="changeType(item)">{{ item.desc }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-show="currentStep === 1">
          <div class="page-main-content-thead">项目信息</div>
          <FormItem label="项目名称" prop="name">
            <Input v-model.trim="formData.name" class="width-l" maxlength="50" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="轮播图" prop="picUrlStrings">
            <sp-upload v-model="formData.picUrlStrings" :img-width="1080" :img-height="720" move :disabled="isShow" :before-upload="beforeUpload"></sp-upload>
            <p class="text-tips">请上传宽高为1080*720的图片</p>
          </FormItem>
          <FormItem label="宣传视频" prop="videoUrlStrings">
            <sp-upload v-model="formData.videoUrlStrings" :max-size="51200" :format="['MP4', 'WebM', 'Ogg']" video :disabled="isShow"></sp-upload>
            <p class="text-tips">为保证播放效果，请尽量上传宽高比为3:2的视频</p>
          </FormItem>
          <FormItem label="参考价格">
            <InputNumber v-model="formData.salePriceMin" :min="0" :max="99999999" :disabled="isShow" />
            -
            <InputNumber v-model="formData.salePriceMax" :min="0" :max="99999999" :disabled="isShow" />
            元/m<sup>2</sup>
          </FormItem>
          <FormItem label="参考总价">
            <InputNumber v-model="formData.saleTotalPriceMin" :min="0" :max="9999999999" :disabled="isShow" />
            -
            <InputNumber v-model="formData.saleTotalPriceMax" :min="0" :max="9999999999" :disabled="isShow" />
            万/套
          </FormItem>
          <FormItem label="项目面积">
            <InputNumber v-model="formData.areaMin" :min="0" :max="99999999" :disabled="isShow" />
            -
            <InputNumber v-model="formData.areaMax" :min="0" :max="99999999" :disabled="isShow" />
            m<sup>2</sup>
          </FormItem>
          <FormItem label="开盘时间" prop="startDate">
            <DatePicker class="width-s" v-model="formData.startDate" type="date" transfer :disabled="isShow" />
          </FormItem>
          <FormItem label="楼盘地址" prop="address">
            <Input v-model.trim="formData.address" class="width-l" maxlength="100" clearable :disabled="isShow" />
            <Button class="margin-l-10" type="info" @click="searchMap" :disabled="isShow">查询</Button>
            <div class="padding-t-10" v-if="myValue && currentStep === 1">
              <sp-map-t :center="mapValue" :point="mapPoint" @on-change="changeMap" :disabled="isShow"></sp-map-t>
            </div>
          </FormItem>
          <div class="page-main-content-thead">楼盘基本信息</div>
          <FormItem label="项目卖点">
            <Input v-model.trim="formData.keywords" class="width-m" maxlength="20" clearable v-show="!isShow" />
            <Button class="margin-l-10" type="info" @click="addKeyword" v-show="!isShow">添加</Button>
            <div :class="{'padding-t-10': !isShow}" v-show="keywordList.length > 0">
              <Tag v-for="(item, index) in keywordList" :key="'keyword' + index" :color="getColor(index)" size="medium" :closable="!isShow" @on-close="removeKeyword(index)">{{ item }}</Tag>
            </div>
          </FormItem>
          <FormItem label="楼盘地址" prop="address">
            <Input v-model.trim="formData.address" class="width-l" maxlength="100" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="开发商" prop="company">
            <Input v-model.trim="formData.company" class="width-l" maxlength="50" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="客服电话" prop="serviceTelephone">
            <Input v-model.trim="formData.serviceTelephone" class="width-s" maxlength="20" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="自定义字段">
            <Button type="info" @click="addBasic" :disabled="isShow">添加自定义字段</Button>
            <div class="padding-t-10" v-for="(item, index) in formData.jibenStrings" :key="'jiben' + index">
              <Input v-model.trim="item.title" class="width-s" maxlength="50" placeholder="请输入标题" clearable :disabled="isShow" />
              <Input v-model.trim="item.content" class="width-m margin-l-10" maxlength="100" placeholder="请输入内容" clearable :disabled="isShow" />
              <Button class="margin-l-10" type="error" @click="removeBasic(index)" :disabled="isShow">删除</Button>
            </div>
          </FormItem>
          <div class="page-main-content-thead">楼盘销售信息</div>
          <FormItem label="产权信息" prop="realProperty">
            <Input v-model.trim="formData.realProperty" class="width-l" maxlength="50" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="在售户型" prop="houseInfo">
            <Input v-model.trim="formData.houseInfo" class="width-l" maxlength="36" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="售楼地址" prop="saleAddress">
            <Input v-model.trim="formData.saleAddress" class="width-l" maxlength="36" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="自定义字段">
            <Button type="info" @click="addSales" :disabled="isShow">添加自定义字段</Button>
            <div class="padding-t-10" v-for="(item, index) in formData.xiaoshouStrings" :key="'sales' + index">
              <Input v-model.trim="item.title" class="width-s" maxlength="50" placeholder="请输入标题" clearable :disabled="isShow" />
              <Input v-model.trim="item.content" class="width-m margin-l-10" maxlength="100" placeholder="请输入内容" clearable :disabled="isShow" />
              <Button class="margin-l-10" type="error" @click="removeSales(index)" :disabled="isShow">删除</Button>
            </div>
          </FormItem>
          <div class="page-main-content-thead">小区概况</div>
          <FormItem label="物业类型">
            <Input v-model.trim="formData.managementInfoDTO.mangerType" class="width-l" maxlength="50" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="物业公司">
            <Input v-model.trim="formData.managementInfoDTO.companyName" class="width-l" maxlength="50" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="物业费用">
            <Input v-model.trim="formData.managementInfoDTO.fee" class="width-l" maxlength="50" clearable :disabled="isShow" />
            <p class="text-tips">例如：住宅6.9元/m2/月，公寓7.5元/m2/月</p>
          </FormItem>
          <FormItem label="绿化率">
            <Input v-model.trim="formData.managementInfoDTO.greeningRate" class="width-s" maxlength="50" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="规划户数">
            <InputNumber v-model="formData.managementInfoDTO.totalNum" class="width-s" :min="0" :max="99999999" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="车位比">
            <Input v-model.trim="formData.managementInfoDTO.parkingSpaceRatio" class="width-s" maxlength="50" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="容积率">
            <Input v-model.trim="formData.managementInfoDTO.plotRatio" class="width-s" maxlength="50" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="自定义字段">
            <Button type="info" @click="addGaikuan" :disabled="isShow">添加自定义字段</Button>
            <div class="padding-t-10" v-for="(item, index) in formData.managementInfoDTO.xiaoquStrings" :key="'gaikuan' + index">
              <Input v-model.trim="item.title" class="width-s" maxlength="50" placeholder="请输入标题" clearable :disabled="isShow" />
              <Input v-model.trim="item.content" class="width-m margin-l-10" maxlength="100" placeholder="请输入内容" clearable :disabled="isShow" />
              <Button class="margin-l-10" type="error" @click="removeGaikuan(index)" :disabled="isShow">删除</Button>
            </div>
          </FormItem>
          <div class="page-main-content-thead">分享海报</div>
          <FormItem label="海报图片">
            <sp-upload v-model="formData.buildingBill" :img-width="750" :img-height="1125" :disabled="isShow"></sp-upload>
            <p class="text-tips">请上传宽高为750*1125的图片</p>
          </FormItem>
        </div>
        <div v-show="currentStep === 2">
          <sp-editor v-model="formData.buildingDetail" :disabled="isShow"></sp-editor>
        </div>
      </Form>
      <div class="drawer-foot">
        <Button type="info" :disabled="currentStep === 0" @click="goPrev">上一步</Button>
        <Button type="primary" :disabled="currentStep >= 2 && isShow" @click="submit">{{ currentStep >= 2 ? '提交' : '下一步' }}</Button>
      </div>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  const formData = {
    address: '',  // 项目地址
    areaMax: null,  // 参考面积最大
    areaMin: null,  // 参考面积最小
    buildingDetail: '', // 项目详情
    buildingBill: '', // 楼盘海报
    buildingNo: '', // 项目ID
    category: null, // 房屋性质种类：0住宅，1公寓，2商业
    cityId: null, // 市
    company: '',  // 开发商
    contactTelephone: '', // 联系电话
    jibenStrings: [], // 楼盘基本信息自定义字段：[{’title’:’标题’,’content’:’内容’},{’title’:’标题’,’content’:’内容’}]
    keywords: '', // 项目卖点：多个热点以英文逗号分隔
    latitude: '', // 纬度
    houseInfo: '',  // 在售户型
    managementInfoDTO: {
      buildingId: null, // 楼盘ID
      companyName: '',  // 物业公司
      fee: '',  // 物业费用
      greeningRate: '', // 绿化率
      mangerType: '',  // 物业类型
      id: null,
      parkingSpaceRatio: '',  // 车位比
      plotRatio: '',  // 容积率
      totalNum: null, // 规划户数
      xiaoquStrings: [],  // 小区概况自定义字段：[{’title’:’标题’，’content’:’内容’},{’title’:’标题’，’content’:’内容’}]
    },
    masterImage: '',  // 主图，前端拿第一张图片
    name: '', // 项目名称
    longitude: '', // 经度
    picUrlStrings: [],  // 轮播图集合，英文逗号分割
    provinceId: null, // 省
    realProperty: '', // 产权信息
    saleAddress: '',  // 销售中心地址
    salePriceMax: null, // 参数单价上限
    salePriceMin: null, // 参数单价下限
    saleTotalPriceMax: null,  // 参数总价上限
    saleTotalPriceMin: null,  // 参数总价下限
    saleType: [], // 销售种类 0即将开盘1热销 2推荐
    serviceTelephone: '', // 客服电话
    startDate: '',  // 开盘时间
    status: null, // 项目状态：1-启用，2-禁用
    townId: null, // 区
    videoUrlStrings: '',  // 宣传视频集合，英文逗号分割
    xiaoshouStrings: []  // 楼盘销售信息自定义字段：[{’title’:’标题’,’content’:’内容’},{’title’:’标题’,’content’:’内容’}]
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      flag: {
        type: String
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        formRule: {
          name: [
            {
              required: true,
              message: '请输入项目名称',
              trigger: 'blur'
            }
          ],
          picUrlStrings: [
            {
              type: 'array',
              required: true,
              message: '请上传轮播图',
              trigger: 'change'
            }
          ],
          videoUrlStrings: [
            {
              required: true,
              message: '请上传宣传视频',
              trigger: 'change'
            }
          ],
          serviceTelephone: [
            {
              required: true,
              message: '请输入客服电话',
              trigger: 'blur'
            }
          ]
        },
        backupData: {},
        currentStep: 0,
        provinceList: [],
        cityList: [],
        townList: [],
        categoryList: [],
        saleTypeList: [],
        mapValue: '',
        colors: ['success', 'warning', 'error', 'blue', 'green', 'red', 'yellow', 'pink', 'magenta', 'volcano', 'orange', 'gold', 'lime', 'cyan', 'geekblue', 'purple'],
        keywordList: []
      };
    },
    computed: {
      title() {
        return {
          'add': '新增',
          'show': '查看',
          'edit': '编辑'
        }[this.flag] + '项目';
      },
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      },
      isAdd() {
        return this.flag === 'add';
      },
      isShow() {
        return this.flag === 'show';
      },
      isEdit() {
        return this.flag === 'edit';
      },
      mapPoint() {
        return {
          lat: this.formData.latitude,
          lng: this.formData.longitude
        };
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
            if (item === 'saleType') {
              this.formData[item] = String(data[item]).split(',').map(v => Number(v));
            }
          }
        });
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        this.getProvinceRegionList();
        this.getCategory();
        this.getSaleType();
        this.cityList = [];
        this.townList = [];
        this.currentStep = 0;
        if (this.isAdd) {
          this.initData(this.data, true);
          return;
        }
        this.$axios({
          url: this.$api.project.queryBuildingDetail,
          data: {
            id: this.data.id
          },
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          let json = Object.assign({}, this.formData, data);
          json.keywords = '';
          json.videoUrlStrings = data.videoUrlStrings[0].url;
          json.jibenStrings = [];
          json.xiaoshouStrings = [];
          data.tbuildingCustomizeInfos && data.tbuildingCustomizeInfos.forEach(item => {
            if (item.customizeType === 1) {
              json.jibenStrings.push({
                title: item.title,
                content: item.content
              });
            } else if (item.customizeType === 2) {
              json.xiaoshouStrings.push({
                title: item.title,
                content: item.content
              });
            }
          });
          if (data.tbuildingManagementInfo) {
            Object.keys(json.managementInfoDTO).forEach(item => {
              if (data.tbuildingManagementInfo.hasOwnProperty(item)) {
                json.managementInfoDTO[item] = data.tbuildingManagementInfo[item];
              }
            });
            json.managementInfoDTO.xiaoquStrings = data.tbuildingManagementInfo.tbuildingCustomizeInfos;
          }
          this.keywordList = data.keywordsStrings || [];
          if (json.latitude) {
            json.latitude = Number(json.latitude);
          }
          if (json.longitude) {
            json.longitude = Number(json.longitude);
          }
          if (json.provinceId) {
            this.getCityList(json.provinceId);
          }
          if (json.cityId) {
            this.getCityList(json.cityId, 'town');
          }
          let _data = Object.assign({}, this.data, json);
          this.initData(_data, true);
        });
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      },
      /**
       * 提交
       */
      submit() {
        if (this.currentStep === 0) {
          if (this.formData.cityId === null) {
            return this.notice('请选择项目城市');
          }
          if (this.formData.townId === null) {
            return this.notice('请选择项目区域');
          }
          if (this.formData.category === null) {
            return this.notice('请选择项目类型');
          }
          if (this.formData.saleType.length === 0) {
            return this.notice('请选择项目分类');
          }
          this.currentStep++;
          if (this.formData.address) this.searchMap();
        } else if (this.currentStep === 1) {
          this.$refs.editForm.validate(valid => {
            if (valid) {
              this.currentStep++;
            }
          });
        } else {
          if (!this.formData.buildingDetail) {
            return this.notice('请输入项目详情');
          }
          this.$confirm('确定要提交吗？').then(() => {
            let url = this.$api.project.addBuilding;
            let data = Object.assign({}, this.formData);
            if (data.longitude) data.longitude = String(data.longitude);
            if (data.latitude) data.latitude = String(data.latitude);
            data.startDate = this.formatDate(data.startDate, 'yyyy-MM-dd');
            data.masterImage = data.picUrlStrings[0].url;
            data.picUrlStrings = data.picUrlStrings.map(item => item.url).join(',');
            data.keywords = this.keywordList.join(',');
            data.jibenStrings = JSON.stringify(data.jibenStrings.filter(item => item.title || item.content));
            data.xiaoshouStrings = JSON.stringify(data.xiaoshouStrings.filter(item => item.title || item.content));
            data.managementInfoDTO.xiaoquStrings = JSON.stringify(data.managementInfoDTO.xiaoquStrings.filter(item => item.title || item.content));
            data.saleType = data.saleType.join(',');
            if (this.isEdit) {
              url = this.$api.project.updateBuilding;
              data.id = this.data.id;
            }
            this.save(url, data);
          });
        }
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '提交成功'
          });
          this.myValue = false;
          this.$emit('success');
        });
      },
      /**
       * 返回上一步
       */
      goPrev() {
        this.currentStep--;
      },
      /**
       * 查询省份列表
       */
      getProvinceRegionList() {
        if (this.provinceList.length > 0) return;
        this.$axios({
          url: this.$api.region.queryProvinceRegionList,
          data: {
            serviceType: 1
          }
        }).then(data => {
          (data || []).forEach(item => {
            this.provinceList.push({
              value: item.regionId,
              label: item.regionName
            });
          });
        });
      },
      /**
       * 获取城市区域列表
       */
      getCityList(parentId, type = 'city') {
        let serviceType = 1;
        let parent = this.cityList;
        if (type === 'town') {
          serviceType = 0;
          parent = this.townList;
        }
        this.$axios({
          url: this.$api.region.queryCityRegionList,
          data: {
            regionParentId: parentId,
            serviceType
          }
        }).then(data => {
          (data || []).forEach(item => {
            parent.push({
              value: item.regionId,
              label: item.regionName
            });
          });
        });
      },
      /**
       * 获取项目类型
       */
      getCategory() {
        if (this.categoryList.length > 0) return;
        this.$axios({
          url: this.$api.project.queryBuildingCategory,
          data: {}
        }).then(data => {
          this.categoryList = data || [];
        });
      },
      /**
       * 获取项目分类
       */
      getSaleType() {
        if (this.saleTypeList.length > 0) return;
        this.$axios({
          url: this.$api.project.queryBuildingSaleType,
          data: {}
        }).then(data => {
          this.saleTypeList = data || [];
        });
      },
      /**
       * 选择项目省份
       */
      changeProvince(item) {
        if (this.isShow) return;
        if (item.value === this.formData.provinceId) return;
        this.formData.provinceId = item.value;
        this.formData.cityId = null;
        this.formData.townId = null;
        this.cityList = [];
        this.townList = [];
        this.getCityList(item.value);
      },
      /**
       * 选择项目城市
       */
      changeCity(item) {
        if (this.isShow) return;
        if (item.value === this.formData.cityId) return;
        this.formData.cityId = item.value;
        this.formData.townId = null;
        this.townList = [];
        this.getCityList(item.value, 'town');
      },
      /**
       * 选择项目区域
       */
      changeTown(item) {
        if (this.isShow) return;
        if (item.value === this.formData.townId) return;
        this.formData.townId = item.value;
      },
      /**
       * 选择项目类型
       */
      changeCategory(item) {
        if (this.isShow) return;
        this.formData.category = item.code;
      },
      /**
       * 选择项目分类
       */
      changeType(item) {
        if (this.isShow) return;
        let index = this.formData.saleType.findIndex(v => v === item.code);
        if (index === -1) {
          this.formData.saleType.push(item.code);
        } else {
          this.formData.saleType.splice(index, 1);
        }
      },
      /**
       * 图片上传限制
       */
      beforeUpload(file) {
        if (this.formData.picUrlStrings.length >= 10) {
          this.notice('轮播图最多只能上传10张');
          return false;
        }
      },
      /**
       * 查询地图
       */
      searchMap() {
        this.mapValue = this.formData.address;
      },
      /**
       * 地址或坐标改变的回调
       */
      changeMap(map) {
        this.formData.address = map.address;
        this.formData.longitude = map.lng;
        this.formData.latitude = map.lat;
      },
      /**
       * 添加项目卖点
       */
      addKeyword() {
        if (!this.formData.keywords) {
          return this.notice('请输入项目卖点');
        }
        this.keywordList.push(this.formData.keywords);
        this.formData.keywords = '';
      },
      /**
       * 获取卖点标签颜色
       */
      getColor(index) {
        let num = index;
        if (index >= this.colors.length) {
          num = index % this.colors.length;
        }
        return this.colors[num];
      },
      /**
       * 删除项目卖点
       */
      removeKeyword(index) {
        this.keywordList.splice(index, 1);
      },
      /**
       * 添加基本信息自定义字段
       */
      addBasic() {
        this.formData.jibenStrings.push({
          title: '',
          content: ''
        });
      },
      /**
       * 删除基本信息自定义字段
       */
      removeBasic(index) {
        this.formData.jibenStrings.splice(index, 1);
      },
      /**
       * 添加销售信息自定义字段
       */
      addSales() {
        this.formData.xiaoshouStrings.push({
          title: '',
          content: ''
        });
      },
      /**
       * 删除销售信息自定义字段
       */
      removeSales(index) {
        this.formData.xiaoshouStrings.splice(index, 1);
      },
      /**
       * 添加小区概况自定义字段
       */
      addGaikuan() {
        this.formData.managementInfoDTO.xiaoquStrings.push({
          title: '',
          content: ''
        });
      },
      /**
       * 删除小区概况自定义字段
       */
      removeGaikuan(index) {
        this.formData.managementInfoDTO.xiaoquStrings.splice(index, 1);
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.drawer .ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 清除表单验证效果
          this.$refs.editForm && this.$refs.editForm.resetFields();
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>